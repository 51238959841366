import * as React from 'react';
import { Box, Container, Flex, Text } from '@chakra-ui/react';

export const Footer: React.FC = () => {
  const now = new Date();

  return (
    <Box py={6} borderTopWidth='1px' borderTopColor='gray.100'>
      <Container maxW='8xl' px={8} bg='white'>
        <Flex align='center' justify={{ base: 'center', md: 'space-between' }} direction={{ base: 'column', md: 'row' }} fontSize='sm'>
          <Text>
            Made with
            <Text as='span' color='red.500'>
              {` ♥ `}
            </Text>
            in Singapore
          </Text>

          <Text>&copy; {now.getUTCFullYear()} HardFury. All rights reserved.</Text>
        </Flex>
      </Container>
    </Box>
  );
};
