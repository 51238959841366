import '~/styles/vars.css';
import '~/styles/markdown.css';
import '~/styles/global.scss';

import * as React from 'react';
import { type GatsbyBrowser } from 'gatsby';

import { WrapPageElement } from '~/provider';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = (props) => {
  return <WrapPageElement {...props} />;
};
