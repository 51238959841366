import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Container, Flex } from '@chakra-ui/react';
import Sticky from 'react-stickynode';

import HardFuryLogo from '~/assets/logo/hardfury-logotype.svg';
import { HoverUnderLineText } from '~/components/chakra';
import { SocialMedia } from '~/components/social-media';
import { slugify } from '~/utils';

interface HeaderProps {
  showBorder?: boolean;
}

const LINKS = [
  'Thinking',
  // 'Projects',
  // 'Shop'
];

export const Header: React.FC<HeaderProps> = ({ showBorder = false }) => {
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      pos='fixed'
      zIndex={9999}
      top={0}
      w='full'
      bg='white'
      borderBottomWidth='1px'
      borderBottomColor={showBorder || scrollPosition > 0 ? 'gray.100' : 'transparent'}
      transition='all'
      transitionDuration='300ms'
    >
      <Container maxW='8xl' px={8}>
        <Flex align='center' justify='space-between' h={16}>
          <Link to='/'>
            <HardFuryLogo />
          </Link>

          <Flex align='center'>
            <Flex align='center' gap={4}>
              {LINKS.map((title) => (
                <Link key={title} to={`/${slugify(title)}`}>
                  <HoverUnderLineText fontWeight='medium'>{title}</HoverUnderLineText>
                </Link>
              ))}
            </Flex>
            <SocialMedia ml={4} pl={4} borderLeft='1px' borderColor='gray.200' />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
