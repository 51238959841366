import * as React from 'react';
import { Flex, type FlexProps, Icon, Link as ChakraLink } from '@chakra-ui/react';
import { TbBrandGithub, TbBrandTelegram, TbBrandX } from 'react-icons/tb';

// https://react-icons.github.io/react-icons/icons/tb/
const socialLinks = [
  { name: 'GitHub', icon: TbBrandGithub, link: 'https://github.com/hardfury-labs' },
  { name: 'X (Twitter)', icon: TbBrandX, link: 'https://twitter.com/HardFuryLabs' },
  { name: 'Telegram', icon: TbBrandTelegram, link: 'https://t.me/HardFuryBot' },
];

export const SocialMedia: React.FC<FlexProps> = (props) => {
  return (
    <Flex align='center' direction='row' columnGap={3} {...props}>
      {socialLinks.map(({ name, icon, link }) => (
        <ChakraLink
          key={name}
          display='flex'
          color='gray.300'
          _hover={{ color: 'gray.900' }}
          transition='all'
          href={link}
          isExternal
          transitionDuration='300ms'
        >
          <Icon as={icon} boxSize={6} />
        </ChakraLink>
      ))}
    </Flex>
  );
};
