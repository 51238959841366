import * as React from 'react';
import { type WrapPageElementBrowserArgs } from 'gatsby';
import { Box, ChakraProvider } from '@chakra-ui/react';

import { Footer } from '~/components/footer';
import { Header } from '~/components/header';
import theme from '~/theme';

export const WrapPageElement = ({ element, props }: WrapPageElementBrowserArgs) => {
  const { uri } = props;

  return (
    // Or ChakraBaseProvider if you only want to compile the default Chakra theme tokens
    <ChakraProvider theme={theme}>
      <Header showBorder={uri !== '/'} />
      <Box as='main' pt={16}>
        {element}
      </Box>
      <Footer />
    </ChakraProvider>
  );
};
