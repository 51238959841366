import { type Colors, defineStyle, defineStyleConfig, extendTheme, type ThemeComponents, type ThemeConfig } from '@chakra-ui/react';

const config: ThemeConfig = {
  cssVarPrefix: 'hf',
};

const colors: Colors = {
  // https://uicolors.app/create
  gray: {
    50: '#f6f6f6',
    100: '#e7e7e7',
    200: '#d1d1d1',
    300: '#b0b0b0',
    400: '#888888',
    500: '#6d6d6d',
    600: '#5d5d5d',
    700: '#4f4f4f',
    800: '#454545',
    900: '#333333',
    950: '#262626',

    // 50: '#f8f8f8',
    // 100: '#f2f2f2',
    // 200: '#dcdcdc',
    // 300: '#bdbdbd',
    // 400: '#989898',
    // 500: '#7c7c7c',
    // 600: '#656565',
    // 700: '#525252',
    // 800: '#464646',
    // 900: '#3d3d3d',
    // 950: '#292929',
  },
  // #333333 is rbga(51, 51, 51, 1)
};

const components: ThemeComponents = {
  // https://chakra-ui.com/docs/styled-system/component-style#styling-single-part-components
  Container: {
    sizes: {
      '7xl': defineStyle({
        // eq container.xl
        maxWidth: '7xl',
      }),
    },
    defaultProps: {
      size: '7xl',
    },
  },
  Text: {
    variants: {
      'hero-title': {
        color: 'gray.900',
        fontSize: { base: '4xl', md: '5xl', lg: '6xl' },
        fontWeight: 'bold',
        textAlign: { base: 'center', lg: 'left' },
      },
      'hero-subtitle': {
        color: 'gray.600',
        fontSize: { base: 'lg', md: 'xl', lg: '2xl' },
        fontWeight: 'medium',
        textAlign: { base: 'center', lg: 'left' },
      },
      title: {
        color: 'gray.900',
        fontSize: { base: '4xl', lg: '5xl' },
        lineHeight: 1,
        textAlign: 'center',
      },
      subtitle: {
        color: 'gray.600',
        fontSize: { base: 'lg', lg: 'xl' },
        textAlign: 'center',
      },
      heading: {
        fontSize: { base: '2xl', lg: '3xl' },
      },
      excerpt: {
        fontSize: { base: 'md', lg: 'lg' },
      },
      frontmatter: {
        color: 'gray.600',
        fontSize: { base: 'sm', lg: 'md' },
        // fontWeight: 'light',
      },
    },
  },
};

const fontFamily = `"Chinese Quotes", "Cachet", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;

// Or export `extendBaseTheme` if you only want the default Chakra theme tokens to extend (no default component themes)
export default extendTheme({
  // https://chakra-ui.com/docs/styled-system/theme
  config,
  fonts: {
    heading: fontFamily,
    body: fontFamily,
  },
  styles: {
    global: {
      'html, body': {
        color: 'gray.900',
      },
    },
  },
  colors,
  components,
});
