import * as React from 'react';
import { Box, HStack, type RadioProps, Text, useRadio, useRadioGroup } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { get } from 'lodash-es';

interface UnderLineTextProps {
  hovercolor?: string;
}

export const UnderLineText = styled(Text)<UnderLineTextProps>`
  border-bottom-width: 0;
  background-image: ${(props) =>
    props.hovercolor
      ? `linear-gradient(${props.hovercolor}, ${props.hovercolor})`
      : 'linear-gradient(var(--hf-colors-gray-900), var(--hf-colors-gray-900))'};
  background-image: linear-gradient(var(--hf-colors-gray-900), var(--hf-colors-gray-900));
  background-size: 100% 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
`;

export const HoverUnderLineText = styled(Text)<UnderLineTextProps>`
  border-bottom-width: 0;
  background-image: ${(props) =>
    props.hovercolor
      ? `linear-gradient(transparent, transparent), linear-gradient(${props.hovercolor}, ${props.hovercolor})`
      : 'linear-gradient(transparent, transparent), linear-gradient(var(--hf-colors-gray-900), var(--hf-colors-gray-900))'};
  background-size: 0 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease-in-out;

  &:hover {
    background-size: 100% 2px;
    background-position: 0 100%;
  }
`;

const RadioButton: React.FC<RadioProps> = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as='label'>
      <input {...input} onClick={props.onClick} />
      <Box
        {...checkbox}
        px={4}
        py={2}
        color='gray.900'
        borderWidth='1px'
        borderColor='gray.100'
        _hover={{
          borderColor: 'gray.900',
        }}
        _disabled={{
          bg: 'gray.100',
          color: 'gray.300',
          cursor: 'not-allowed',
          _hover: {
            borderColor: 'gray.100',
          },
        }}
        _checked={{
          borderColor: 'gray.900',
          bg: 'gray.900',
          color: 'gray.100',
        }}
        userSelect='none'
        cursor='pointer'
        transition='all'
        rounded='sm'
        transitionDuration='300ms'
      >
        {props.children}
      </Box>
    </Box>
  );
};

interface RadioButtonGroupProps {
  name: string;
  value?: string;
  options: string[];
  disabledOptions?: Record<string, boolean>;
  onClick: (value: string) => void;
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ name, value, options, disabledOptions = {}, onClick }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({ name, value });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {options.map((option) => {
        const radio = getRadioProps({ value: option });

        return (
          <RadioButton key={option} isDisabled={get(disabledOptions, option, false)} onClick={() => onClick(option)} {...radio}>
            {option}
          </RadioButton>
        );
      })}
    </HStack>
  );
};
